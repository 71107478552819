//
// Basic print styles
// --------------------------------------------------
// Source: https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

@media print {


  .sidebar-offcanvas,
  #portal-footer-wrapper,
  #mainnavigation-wrapper,
  #content-header,
  .link-https,
  #edit-zone {
    display:none;
    visibility: hidden;
  }

  * {
    text-shadow: none !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    background: transparent !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    // content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  // Don't show links for images, or javascript/internal links
  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
  // Once fixed, we can just straight up remove this.
  select {
    background: #fff !important;
  }

  // Bootstrap components
  .navbar {
    display: none;
  }
  .table {
    td,
    th {
      background-color: #fff !important;
    }
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

  #portal-searchbox {
    display: None;
  }

  #portal-personaltools {
    display: None;
  }

  #portal-breadcrumbs {
    display: None;
  }


  // Theme components
  div.outer-wrapper{
    padding-top: 0;
  }
  #commenting,
  .fieldErrorBox,
  .round-btn,
  form,
  .context,
  .standalone,
  #plone-document-byline,
  .input-group,
  header#content-header{
    display: none !important;
  }
  .autotoc-nav{
    margin-right: 15px;
  }

  .field{
    label{
      width: 30%;
      text-align: right;
      margin-right: 2%;
      color: #57bc90;
    }
    > br{display: none;}
    > p, > div, > table{
      border: none !important;
      padding: 0 !important;
      display: inline-block;
      width: 66% !important;
    }
  }

}
