//*// CONTENTTYPE ICONS //*//

[class*="contenttype-"]::before,
.contenttype-file #crud-edit-File-widgets-view_title::before {
    font-family:"Fontello";
    font-size: 100%;
    padding: 0;
    margin:0;
    position: relative;
    left: inherit;
    display: inline-block;
    color: inherit;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: @plone-padding-base-vertical;
    content: '\e834';
}

.contenttype-folder::before     { content: '\e801'; }
.contenttype-document::before   { content: '\e80e'; }
.contenttype-file::before       { content: '\e811'; }
.contenttype-link::before       { content: '\e806'; }
.contenttype-image::before      { content: '\e810'; }
.contenttype-collection::before { content: '\e808'; }
.contenttype-event::before      { content: '\e809'; }
.contenttype-news-item::before  { content: '\e80f'; }

.outer-wrapper {
    .contenttype-file::before   { content: none;    }
}

.contenttype-file #crud-edit-File-widgets-view_title::before {
    content: '\e811';
}

.mime-icon { margin-right:8px; }


// custom icons / fontawesome
.contenttype-lif:before,
.contenttype-resource:before,
.contenttype-project:before,
.contenttype-topic:before,
.contenttype-conversation:before,
.contenttype-message-board:before,
.contenttype-country-profile:before,
.contenttype-member-folder:before,
.contenttype-video-conference:before,
.contenttype-media-link:before,
.icon-faceted_settings:before { 
    font-family: "FontAwesome";
    font-size: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    left: inherit;
    display: inline-block;
    color: inherit;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: 6px;
}

.icon-faceted_settings:before{ content: '\f1de'; }
.contenttype-media-link:before{ content: '\f0c1'; }
.contenttype-video-conference:before{ content: '\f03d'; }
.contenttype-member-folder:before{ content: '\f07c'; }
.contenttype-country-profile:before{ content: '\f024'; }
.contenttype-topic:before,
.contenttype-conversation:before,
.contenttype-message-board:before{ content: '\f27a'; }
.contenttype-project:before{ content: '\f0ae'; }
.contenttype-resource:before{ content: '\f013'; }
.contenttype-lif:before{ content: '\f1ab'; } 



