/* our custom styles */
h1,h2,h3,h4,h5,h6{
    font-family: @plone-font-family-heading;
}

/* TinyMCE templates */
// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @plone-grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @plone-grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// override tile title 
section.existing-content-tile > h2 {
    margin-bottom: 28px;
}

// shareButton-wrapper 
#shareButton-wrapper.white-background{
    font-size: initial;
}

// Override for form fields
.select2-container-multi .select2-choices {
    min-height: 42px;
}
.datagridwidget-table-view .header{
    font-size: inherit !important;
}
.datagridwidget-cell input[type="text"] {
    padding: 10px 12px;
}
.zoomButton {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: transparent;
    text-align: center;
    margin-right: 1px;
    color: #767676;
    cursor: pointer;
    font-weight: bold;
    border-radius: 50%;
    font-size: 24px;
    border: 1px solid #767676;
    line-height: 1em;
}
.zoomButton:hover{
    background-color: #767676;
    color: #fff;
}

label.checked span.label, label.unchecked span.label {
    font-size: inherit;
    color: inherit;
}

.relateditems-widget.relationlist-field {
    ul {
        list-style: none;
        padding-left: 0;
        li {
            margin-bottom: 10px;
        }
    }
}

// navigation
#portal-globalnav{
    display: none;
}

// Main Header
#content-header #portal-top {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

// form field override 
#content form#form {
    padding: 10px;
    background-color: #f6f6f9;    
}
.template-profile_view,
.template-view {
    .field {
        > div {
            border: 1px solid #051C2C;;
            padding: 10px;
            background-color: #fff;             
        }
        > div:empty{
            border: none;
            padding: 0;  
        }
    }
}

table#form-widgets-affiliations > thead > tr > th{
    vertical-align: top;
}
select[id$='-widgets-organization'],
select[id$='-widgets-role']{
    max-width: 15em !important;
}

input[id$='-widgets-other_organization'],
input[id$='-widgets-other_role'] {
	min-width: 10em !important;
}

select[id$='_aghub_themes-from'],
select[id$='_aghub_themes-to']{
    max-width: 100% !important;
    font-size: initial;
}
button[name='to2fromButton'],
button[name='from2toButton']{
    display: inline !important;
}

// commen discussion image 
.discussion {
    .commentImage {
        height: 32px;
        width: 32px;
        img{
            width: 100%;
        }
    }
}

// image circle 
.image-circle {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    img{
        width: 100%;
    }
}

ul#user-action{
    list-style: none;
    padding-left: 0;
    position: absolute;
    top: 35px;
    right: 20px;
    margin: 0;
    li{
        display: inline;
    }
    a{
        color: #000;
        font-size: 24px;
        display: inline-block;
        height: 32px;
        width: 32px;
        border: transparent;
        border-radius: none;
        text-align: center;
        padding: 0;
    }
}
// for mosaic view 
.viewpermission-none{
    ul#user-action{
        a{
            color: #fff;
        }
    }
    .translation_service{
        .wg-drop.country-selector{
            .wgcurrent {
                a {
                    // color: #767676 !important;
                    color: @plone-link-color;
                    padding: 0 10px;
                }
            }
            .wgcurrent:after{
                color: #767676 !important;
                display: none;
            }
        } 
    }
    #top-tier{
        div.round-bordered-icon{
            // background-color: #000;
            a{
                color: #fff;
            }
        }
    }
    #portal-footer-wrapper{
        margin-top: -190px;
    }
}

// calendar
section.calendar-wrapper {
    .calendar-header{
        font-size: 36px;
        padding: 0;  
        font-weight: bold;  
        text-align: center;    
    }
    .calendar-left{
        width: 40%;
        table {
            border: none;
            background-color: transparent;
            color: #051C2C;
        }
        table td{
            border: 2px solid #051C2C;
        }
        table th{
            border: none;           
        }
        .wrapper{
            background-color: #c0e55b;
            background-image: none;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: none;
            padding: 30px;
        }
        th{
            text-transform: uppercase;
            color: #051C2C;
        }        
        th,td{
            padding: 10px;
            text-align: center;
            height: 48px;
            width: 48px;;
        }
        .event.today{
            position: relative;
            overflow: hidden;
            a,
            span{
                background-color: #fbf1a6;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                position: absolute;
                transform: translate(-50%, -5%);
                border: 2px solid transparent !important;
            }            
        }
        .event.cal_has_events{
            a,
            span{
                background-color: transparent;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                position: absolute;
                transform: translate(-50%, -5%);
                border: 2px solid #fbf1a6;
            }            
        }
        .event.today.cal_has_events{
            a,
            span{
                background-color: #fbf1a6;
                border: 2px solid #ffea00;
            }           
        }
        .event.cal_prev_month,
        .event.cal_next_month{
            color: #f1ebc5;
        }
    }
    .calendar-right{
        width: 60%;
        color: #051C2C;
        section {
            div:first-child{
                padding-left: 20px;
                margin-bottom: 10px;
                margin-left: 20px;
            }
            > div{                
                .event-item-info{
                    padding: 15px 20px 20px 40px;
                    margin: 0;
                    p{
                        margin-bottom: 0;
                    }
                    span{
                        color: #000;
                    }
                    span:first-child{
                        color: inherit;
                        margin-bottom: 5px;
                        display: block;
                        font-weight: bold;
                    }
                }
                .event-item-info:hover{
                    background-color: #ccddd3;
                }
            }
        }
    }
}

// summay view 
.folder-summary{
    .folder-summary-item {
        justify-content: space-between;
        margin-bottom: 20px;
        border: 1px solid #051C2C;
        min-height: 100px;
        overflow: hidden;
        background-color: #fff;
        padding: 15px;
        .item-image{            
            img{
                width:100%;
                padding-right: 15px; 
            }
        }
        .item-info{                      
            width: 100%;
        }
    }
}

// portlet profile 
div#profile-social.flex-header-center {
    flex-direction: row;
} 

// agh table 
table.agh-listing{
    width: 100%;
    margin-bottom: 22px;
     > thead > tr > th, 
     > tbody > tr > th, 
     > tfoot > tr > th, 
     > thead > tr > td, 
     > tbody > tr > td, 
     > tfoot > tr > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 0 solid #fff;
        border-collapse: collapse;
        .dropdown-menu{
            i.fa{
                display: none;
            }
        }
    }
    > thead > tr:nth-child(odd) > th{
        background-color: rgba(207, 224, 216, 1)
    }
    > tbody > tr:nth-child(even) > th,
    > tbody > tr:nth-child(even) > td{
        background-color: rgba(192, 229, 91, 1)
    }
}
table.agh2-listing{
    width: 100%;
    border: none !important;
    margin-bottom: 22px;
     > thead > tr > th, 
     > tbody > tr > th, 
     > tfoot > tr > th, 
     > thead > tr > td, 
     > tbody > tr > td, 
     > tfoot > tr > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: middle;
        border-top: 0 solid #e5e5e5;
        border-collapse: collapse;
    }
    > thead > tr:nth-child(odd) > th{
        background-color: rgb(255, 255, 255);
    }
    > tbody > tr:nth-child(even) > th,
    > tbody > tr:nth-child(even) > td{
        background-color: rgba(213, 213, 213, 0.5)
    }
    > thead > tr{
        border-bottom: 2px solid rgba(213, 213, 213, 0.5);
    }
    > thead > tr > th,
    > tbody > tr > td{
        border: none !important;
    }
}
table.agh3-listing{
    border: none;
    > thead > tr, 
    > tbody > tr, 
    > tfoot > tr, 
    > thead > tr, 
    > tbody > tr, 
    > tfoot > tr{
        border-top: 1px solid @plone-link-color;
    }
    > thead > tr > th, 
    > tbody > tr > th, 
    > tfoot > tr > th, 
    > thead > tr > td, 
    > tbody > tr > td, 
    > tfoot > tr > td {
        border: none;
        padding: 10px 15px;
    }
}


// agh dropdown
.agh-dropdown,
.agh-dropdown-button{
    .dropdown-menu{        
        .dropdown-item{
            display: block;
            padding: 5px 15px;
            border-bottom: none;
            font-size: 16px;
            i{
                margin-right: 5px;
            }
        }
        .dropdown-item:hover{
            background-color: #f1ebc5;
        }
    }
}
button.no-style{
    background: none transparent;
    padding: 0;
    border: none;
    text-align: center;
    width: 100%;
}button.no-style:hover{
    box-shadow: none;
}
button.no-style:focus{
    border: none;
}

// country profile 
.country-profile{
    box-shadow: none !important;
    .autotoc-nav{
        background: none transparent !important;
        border: none !important;
        margin: 0 0 20px 0 !important;
        a{
            border: none !important;
            font-weight: bold !important;
            margin: 20px 0 !important;
            padding: 0 !important;
            margin-right: 40px !important;
        }
        a.active{
            color: #57bc90 !important;
            border-bottom: 5px solid #57bc90 !important;
        }
    }
    .country-profile-wrapper{
        .country-map{
            width: 25%;
            .mapwrapper{
                height: 400px;
                overflow: hidden;
                img.map{
                    height: 100%;
                    width: auto;
                }
            }       
            img.flag{
                width: auto;
            }
            .big-icon{
                position: relative;
                overflow: hidden;
                width: 281px;
                height: 281px;
                background-color: #75cbeb;
                border-radius: 50%;
                padding: 15px;
                i{
                    font-size: 170px;
                    position: absolute;
                    top: 21%;
                    left: 19%;
                }
            }
        }     
        .country-info-inner{
            width: 75%;
            .country-prof-field{
                .country-prof-field-inner{
                    height: 64px;
                    position: relative;
                }                
                margin-bottom: 40px;
                > span.formHelp{
                    margin-left: 74px;
                    display: inline-block;
                    font-size: 16px;
                    min-width: 236px;
                }
            }
            .country-prof-field2{
                margin-bottom: 40px;
                .field2-inner{
                    .field2-icon{
                        width: 64px; 
                        .round-bordered-icon{
                            margin-bottom: 15px;
                            height: 64px;
                            width: 64px; 
                            span{
                                height: 64px;
                                width: 64px;   
                                i{
                                    font-size: 32px;
                                }   
                            }                                            
                        }
                    }                    
                    .field2-info{
                        flex:1;
                        margin-left: 20px;
                        span{
                            display:block;
                        }
                        span.value{
                            color: @plone-link-color;
                            font-size: 48px;
                            margin-top: -20px;
                        }
                        span.label{
                            color: @plone-text-color; 
                            font-family: @plone-font-family-heading;                           
                        }
                        span.formHelp{
                            color: @plone-gray;
                            font-size: 16px;
                        }
                    }
                }
            }
        }   
        .country-prof-icon{
            width: 64px;
            height: 64px;
            position: absolute;
            overflow: hidden;
            background: #75cbeb;
            font-size: 32px;
            border-radius: 50%;   
        }
        .country-prof-info {
            height: 48px;
            margin-bottom: 8px;
            margin-top: 8px;
            position: relative;
            overflow: hidden;
            z-index: -1;
            display: table;
            background-color: #404c7f;
            border-radius: 24px;
            font-size: 16px;
            span{
                height: 48px;
                padding: 0 30px;
                color: #fff;           
                width: auto;     
                display: table-cell;
                vertical-align: middle;                
            }
            span.label{
                background-color: #d5d5d5;
                color: #404c7f;;
                width: 320px;
                padding-left: 74px;
                border-radius: 24px;
            }
            span.label + span{
                min-width: 120px;
                text-align: right;
            }
        }
        .country-prof-info.fullwidth{
            background-color: #d5d5d5;
        }
    } 
    
    .country-inner-wrapper {
      clear: both;            
      .country-text-inner-wrapper{  
        min-height: 120px; 
        margin-bottom: 20px;   
        .country-icon{
          width: 120px;
          float: left;
          i{
            position: relative;
            display: inline-block;
            height: 120px;
            width: 120px;
            overflow: hidden;
            border-radius: 50%;
            vertical-align: middle;
            background-color: #f1ebc5;
            font-size: 100px;
            text-align: center; 
          }
          i:before {
            position: absolute;
            transform: translate(-50%, 15%);
          }
        }
        .country-info{
          margin-left: 140px;
          .formHelp{
            font-size: 14px;
            margin: -10px 0 10px 0;
          }
          p.value{
            font-size: 40px;
            font-weight: bold;
          }
          ul{

          }
        }
      }
    } 
    .country-inner-wrapper:empty{
      display: none;
    }  
}

// custom field view
.country-prof-icon > i{
	position: absolute;
    top: 25%;
    left: 25%;
}


// translation 
#top-tier{
    position: absolute;
    top: 0;
    right: 15px;
    display: table;
    > *{
        display: inline-block;
    }
    .round-bordered-icon{
        border: none;
        position: relative;
        overflow: hidden;
        margin: auto 5px;        
        a{
            color: #000;
            height: 36px;
            width: 36px;   
            font-size: 14px;
            i{
                font-size: 24px;
            }   
            i:before {
                transform: translate(0%, 10%);
                display: inline-block;
            }
        }
        button.btnlink{
            padding: 0;
        }
    }
    .round-bordered-icon.dropdown{
        overflow: initial;
        border: none;
        margin-right: 10px;        
        > a{
            border-radius: 50%;
        }
        .dropdown-menu{
            width: auto;
            right: 0;
            left: auto;
            a{
                color: black;
                display: block;
                padding: 10px;
                min-width: 100px;
                text-align: left;
                height: initial;
                width: initial;
            }
            a:hover{
                background-color: #f1ebc5;
            }
        }
    }
    span.round-bordered-icon{
      height: 24px;
      width: 24px;
      font-size: inherit;
      vertical-align: middle;
      text-align: center;
      margin-right: 0 !important;
      line-height: 25px;
    }
    .translation_service{   
      position: relative;
    //   top: -7px; 
      .wg-drop.country-selector {
        background: none transparent !important;
        .wgcurrent {
          border: none transparent;
          a {
            padding: 0 !important;
					}					
        }
        .wgcurrent:after{
          color: #767676 !important;
          display: none;
        }
        ul{
          background-color: #f5f5f5 !important;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
          border: none;
					width: inherit !important;
				}				
      }        
    }
    .translation_service.lang-trigger{
      top: 0px; 
      span{
          font-size: 14px;
          display: inline-block;
          cursor: pointer;
          background-color: #767676 !important;
      }
    }
}


// faceted form 
#faceted-form{ 
    .faceted-widget{
        background-color: #fff;
    }   
    #center-top-area{
        fieldset {
            margin: .5em;
            padding: 1em .5em .5em .5em;
            border: none;
            legend {
                display: none;
            }
            input[type="color"], input[type="date"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="range"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select{
                width: auto;
                display: inline-block;
            }
        }
        #center---default---widgets{
            display: flex;
            flex-direction: row;
            margin-top: 0px;
            margin-bottom: 0px;
            -webkit-box-pack: justify; 
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            .faceted-widget{
                display: inline-block;
            }
        }
    }
}


// mobile menu 
#mobile-menu-wrapper{
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 0;
    display: none;
    z-index: 99;
    min-height: 500px;    
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12px;
    .container{
        padding-top: 60px;
        padding-bottom: 120px;
    }
    a#mobile-menu-close-trigger {
        float: right;
        margin-top: 20px;
        margin-right: 20px;
        font-size: 24px;
    }
    ul#mobile-menu{
        padding-left: 0;
        list-style: none;
        line-height: 30px;      
        > li{
            position: relative;
            input,label{
                display: none;
            }
            > a{
                font-size: inherit;                
                background: #75cbeb;
                border-radius: 0;
                color: #051C2C !important;
                display: block;
                padding: 3px 5px;
                margin-bottom: 5px;
            }
            .nav-item-toggle{
                position: absolute;
                right: 5px;
                top: 12px;
                margin-bottom: 0;
                width: 16px;
                height: 16px;
                overflow: hidden;
                padding: 5px 3px;
                span.caret{
                    display: block;
                }
            }
            > ul{
                list-style: none;
                padding-left: 20px;
                height: auto !important;
                border: none;
                display: none;
            }
        }
        
    }
}
#mobile-menu-wrapper.open-menu{
    display: block;
}

// floatTL
div#floatTL {
    display: none;
}

/* page title  */
#pagetitle{
    background-position: top right;
    background-repeat: no-repeat;
    box-shadow: inset 0px 10px 15px -10px rgb(0 0 0 / 10%);
    padding: 50px 15px 70px;
    -webkit-transform: skewy(-3.5deg);
	-ms-transform: skewy(-3.5deg);
		transform: skewy(-3.5deg);
	
	#pagetitle-content {
		-webkit-transform: skewy(3.5deg);
		-ms-transform: skewy(3.5deg);
		transform: skewy(3.5deg);
	}
}

.angleskew{
    background-position: top right;
    background-repeat: no-repeat;
    box-shadow: inset 0px 10px 15px -10px rgb(0 0 0 / 10%);
    padding: 50px 0;
    -webkit-transform: skewy(-3deg);
	-ms-transform: skewy(-3.5deg);
        transform: skewy(-3.5deg);
    
    .text-wrapper,
    .slidetext-container {
		-webkit-transform: skewy(3.5deg);
		-ms-transform: skewy(3.5deg);
		transform: skewy(3.5deg);
	}
}

// header
@media (min-width: @plone-screen-md-min) {
    #top-tier{top:5px;}
    #portal-globalnav{
        display: block;
        // flex: auto;
        margin-left: 50px;
    }
    #mobile-menu-open-trigger{
        display: none !important;
    }
    .audiofile{
        div:first-child{
            width: 65%;
        }
    }
    .template-media_view,
    .portaltype-media-link.template-view{
        audio{
            // width: 100 !important;
            // transform: scale(1.2);            
        }
    }

    ul#user-action{
        display: none;
    }

    #portlet-profile-image .profile-action-link a i:before{
        transform: translate(0%, 20%) !important;
    }
}

// overlay on stitch line 
.viewpermission-none{
    .mosaic-IRichTextBehavior-text-tile {
        position: relative;
        z-index: 10 !important;
        p {
            // background-color: rgba(255, 255, 255, 0.6);
        }
    }
    .mosaic-tile-content {
        .video-carousel,
        .slider.slick-slider,
        .container {
            position: relative;
            z-index: 10 !important;
        }        
    }
    .mosaic-ISourceLink-source_link-tile{
        #form-widgets-ISourceLink-source_link{
            display: block;
            text-align: right;
            margin: 10px 0;
        }
        #form-widgets-ISourceLink-source_link::before{
            content: "Source:";
            display: inline-block;
            color: #767676;
            font-weight: 400;
        }
    }
}
.viewpermission-add-portal-content,
.viewpermission-modify-portal-content,
.viewpermission-plone-site-setup-overview,
.viewpermission-view{

    .outer-wrapper{
        position: relative;
        z-index: 10 !important;
    }
    #content-core {
        p {
            // background-color: rgba(255, 255, 255, 0.6);
        }
    }
}
.viewpermission-view{

}
#portal-footer-wrapper{
    background-image: url(/++theme++my-theme/images/bg-footer.svg);    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    .container{
        position: relative;
        z-index: 10 !important;
    }
    #agh-copyright{
        font-size: @plone-font-size-small;
    }
    .agh-footer{
        #footer-nav{
            ul{
                padding-left: 0;
                list-style: none;
            }
        }
    }
}
.asiamap #info-container,
#asia_map_div #info-container {
    // background-image: url(/++theme++my-theme/images/agh-pre-made-thread8.svg);    
    background-image: none;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

// leadimage listin view 
.blog-entry {
    cursor: auto;
    margin-right: 50px;
    margin-bottom: 20px;
    min-height: 278px;
    padding: 0;
    border: 1px solid #051C2C;
    background-color: #fff;  
    overflow: hidden;
    position: relative;  
    z-index: 9;
    .blog-image {
        float: left;
        position: relative;
        overflow: hidden;
        width: 25%;
        padding: 15px;
        p{
            margin-bottom: 0;
            line-height: inherit;
        }
        audio{
            background-color: #f1f3f4;
        }
        video,
        audio,
        iframe,
        img {
            width: 100%;
        }
    }
    .blog-meta {
        float: left;
        padding: 15px;
        width: 74%;
        h4{
            margin-top: 0;
        }
        p{
            margin-bottom: 20px;
        }
    }
    .news_title {
        margin-top: 0;
        text-decoration: none;
    }
    .news_desc {
        color: #000;
    }
    .news_link, .news_link a {
        text-decoration: none;
        border-bottom: none !important;
    }
    .blog-byline {
        color: #585858;
        font-size: 16px;
        span.round-prof-img{
            position: relative;
            display: inline-block;
            height: 48px;
            width: 48px;
            overflow: hidden;
            border-radius: 50%;
            vertical-align: middle;
        }
        a{
            border-bottom: none !important;
        }
    }
    .blog-portrait {
        border-radius: 22px;
        height: 44px;
        margin-right: 10px;
        width: 44px;
    }
}

// banner by line 
.banner-byline {
    color: inherit;
    font-size: 16px;
    a{
        span.banner-portrait-wrapper{
            width: 44px;
            height: 44px;
            position: relative;
            overflow: hidden;
            display: inline-block;
            padding: 0;
            border-radius: 50%;
        }
        img{
            vertical-align: middle;
        }
    }
    .banner-portrait {
        border-radius: 22px;        
        margin-right: 10px;
        
    } 
    span {
        display: inline-block;
        padding-top: 10px;
        vertical-align: middle;
        padding: 0;
        color: inherit;
        
    }   
}
.banner-byline:after{
    content: "";
    display: block;
    clear: both;
    height: 20px;
}

.icon-list{
    border: 1px solid #051C2C;
    margin-bottom: 20px;
    padding: 20px;
    .icon-list-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        align-items: flex-start;

        .icon-list-icon:empty{
            display: none;
        }

        &:before, &:after{
            width: 0;
        }
        .icon-list-icon{
            width: 48px;
            margin-right: 20px;
            flex-shrink: 0;
            span::empty{
                display: none;
            }
            span{
                position: relative;
                display: inline-block;
                height: 48px;
                width: 48px;
                overflow: hidden;
                border-radius: 50%;
                vertical-align: middle;
                background-color: #f1ebc5;
                font-size: 32px;
                text-align: center;
            }
            span.ag_icon-user{
                background-color: #EDBDDB;
            }
            span::before {
                transform: translate(0%, 24%);
                display: inline-block;
            }
            p,
            span.ag_icon-{
                display: none;
            }
        }
        .icon-list-text{
            margin-left: 0;

            h4{
                margin-top: 0;
            }

            &:before, &:after{
                display: block;
            }
            > .context{
                margin-bottom: 5px;
            }
        }
    }
}


// footer 
#portal-footer{
    .doormat{
        .headline{
            h4{
                color: inherit;
                font-weight: bold;
            }            
        }
        .portletContent{
            margin: 20px 0;
            ul{
                list-style: none;
                padding: 0;
                li{
                    a{}
                    a:hover{}
                }
            }
        }
    }
}

// search box 
#portal-searchbox{
    float: none;    
    input[type="text"]{
        width: 100%;
    }
}

// video 
.template-file_view,
.template-media_view,
.template-view{
    video,
    iframe[allow="encrypted-media"],
    iframe#ytplayer {
        max-width: 840px;
        height: 472.5px;
        width: 100%;
    }    
    audio{
        width: 100%;
        border-radius: 0;
        background: #f1f3f4;
        box-shadow: 0 0 20px 0 #0000003b;
    }
}
.template-view,
.template-media_view {
    video{
        height: auto !important;
    }
}

// datagrid add/remove 
.datagridwidget-manipulator{
    vertical-align: middle;
    a{
        color: rgb(115, 214, 115);
        border-bottom: none !important;
        display: inline-block;
        padding: 5px;
        span{
            display: block;
        }
    }    
}
.datagridwidget-manipulator.delete-row{
    a{
        color: rgb(240, 167, 167);
    }   
}

// custom related items
.cstm-related {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin-top: 15px;
    #relatedItemBox{
        header{
            font-family: @plone-font-family-heading;
            margin-bottom: 20px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: flex;
                padding: 1rem;
                @media(min-width: 40rem) {
                    width: 50%;
                }
                @media(min-width: 56rem) {
                    width: 25%;
                }
                a{
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    &:hover {
                        .thumb-icon {
                        filter: contrast(100%);
                        }
                    }
                    .thumb-icon{
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    span{
                        padding: 10px 0;
                        display: block;
                    }
                }
            }                
        }
    }
}


nav#source-link{
    text-align: right;
    margin-bottom: 12px;
    ul{
        list-style: none;
        padding: 0;
        display: inline-block;
    }
}
nav#category {
    text-align: right;
}

// dashboard 
#dashboard{
    display: flex;
    flex-wrap: wrap;
    a{
        border-bottom: none;
    }
    > div{
        flex: 25%;
        max-width: 25%;
        padding: 5px 10px;
        form.portlet-action{
            margin: 0 -3px;
            button{
                padding: 3px 5px;
            }
        }
    }
    > .visualClear{
        display: none;
    }
    @media (max-width: 800px) {
        > div {
            flex: 50%;
            max-width: 50%;
        }
    }
    @media (max-width: 600px) {
        > div {
            flex: 100%;
            max-width: 100%;
        }
    }
}

// agh accordion
.agh-accordion{
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 10px;
    margin-bottom: -1px;
    background-color: #fff;
    .collapse-head{
        position: relative;
        overflow: hidden;
        span{
            position: absolute;
            right: 0;
            top: 8px;
            font-size: 24px;
            font-weight: bold;
        }
        span[aria-expanded='true']{
            i{
                transform: rotate(180deg);
            }
        }
    }
}

// override for faceted nav 
.faceted-area.center-and-left-area {
    margin-right: -20em;
}
.faceted-area.right-area {
    width: 18em;
}

.faceted-area.center-area {
    margin-left: -20em;
}
.faceted-area.left-area {
    width: 18em;
}
.right-area-js {
    margin-right: 19em;
}  
.left-area-js {
    margin-left: 19em;
}
.faceted-form legend{
    font-size: 24px;
    font-weight: normal;
    font-family: @plone-font-family-heading;
}
.faceted-form{
    input[type="color"], input[type="date"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="range"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, select{
        border: 1px solid #051C2C !important;
    }
    fieldset {
        border: 1px solid #051C2C !important;
    }
} 
.faceted-center-widgets div.faceted-text-widget input[type=submit],
.faceted-left-column div.faceted-text-widget input[type=submit] {
    float: right;
    padding: 10px 15px;
    font-weight: normal;
}

.faceted-right-column div.faceted-text-widget input[type=submit] {
    float: right;
    padding: 10px 15px;
    font-weight: normal;
}
.faceted-center-widgets div.faceted-text-widget input[type=text],
.faceted-left-column div.faceted-text-widget input[type=text] {
    width: 70% !important;
    display: inline-block;
}

.faceted-right-column div.faceted-text-widget input[type=text] {
    width: 73%;
    display: inline-block;
}

// faceted edit 
#faceted-edit-widgets .faceted-area.center-content-area {
    margin: 0 20em 0 20em;
}
#faceted-edit-widgets .faceted-add-button span {
    padding: .4em;
}

.faceted-radio-widget ul,
.faceted-checkboxes-widget.faceted-checkbox-widget ul {
    padding-left: 0;
}

// override portlet 
.managedPortlet.portlet form button {
    padding: 3px 5px;
    font-size: 16px;
    background-color: white;
} 

// override for @@consent 
label input.required::after{
    content: none;
}

// configlets 
.configlets{
    .inner-configlet{
        a{
            font-size: 16px;
        }
    }
}

/*override modal search*/
.plone-modal-body{
    #portal-searchbox{
        margin-bottom: 20px;
        input.searchButton{
            font-size: large;
            padding: 11px;
            margin-left: 20px;
        }
    }
}

/*override search page*/
form.searchPage{
    > .input-group{
        margin: 40px 0;

        .input-group-btn > input{
            font-size: large !important;
            padding: 11px 15px;
            margin-left: 10px;
        }
    }
    .actionMenu{
        button{
            font-size: large !important;
            padding: 11px 15px;           
        }
    }
    
}

/*searchpage result*/
#search-results-wrapper{
    ol.agh-searchresult{
        padding-left: 0;
        list-style: none;
        margin: 20px -12px 6px;

        > li {
            border: 1px solid #051C2C;
            padding: 20px;
            margin: 0 0 20px 0 !important;
            display: flex;

            > .resultimg{
                width: 64px;
                margin-right: 15px;

                img{
                    width: 100%;
                }
            }

            > .resultcontent{
                > *{
                    display: block;                    
                }
                > .result-title{
                    word-break: break-word;
                    margin-bottom: 5px;
                }
                .croppedDescription,
                .discreet,
                .documentLocation{
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: @plone-screen-md-min) {
    // override for mobile view      

    // facete nav 
    .faceted-area.center-and-left-area {
        margin-right: 0;
    }
    .faceted-area.right-area {
        width: 100%;
        display: block
    }
    
    .faceted-area.center-area {
        margin-left: 0;
    }
    .faceted-area.left-area {
        width: 100%;
        display: block
    }
    .right-area-js {
        margin-right: 0;
    }  
    .left-area-js {
        margin-left: 0;
    }
    
    // text size for small screen
    h1.documentFirstHeading{
        font-size: 48px;
    }
    h2{
        font-size: 28px;
    }
    h3{
        font-size: 24px;
    }
    h4,
    #portal-footer .doormat .headline h4{
        font-size: 20px;
    }
    .documentDescription{
        font-size: 20px;
    }
    body,
    .standalone, .context, .destructive, [type="submit"], button{
        font-size: 16px;
    }
    #portal-footer .doormat .headline h4{
        font-size: 20px;
    }


    .blog-entry{
        margin-right: 0;
        .blog-image {
            width: 100%;
            img {
                width: 100%;
            }
        }
        .blog-meta {
            width: 100%;
        }
    }

    #portal-footer{
        .doormat{
            text-align: center;
        }
    }
    .audiofile.flex{
        flex-direction: column;
    }
    .get-updated{
        padding: 30px 15px;
        h2{
            font-size: 24px;
        }
        .get-updated-feature-item{
            position: inherit;
            top: auto;
            left: auto;
        }
    }
    .mapcontainer.mapael{
        height: auto !important;
    }

    // register tile 
    #custom-reg-form .container h2{
        width: 60% !important;
    }
    #custom-reg-form{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    #custom-reg-form form#form{
        width: 100% !important; 
        padding: 0 15px !important;       
    }

    // calendar title 
    section.calendar-wrapper{
        .calendar-left {
            width: 100%;
            margin-bottom: 40px;
        }
        .calendar-right{
            width: 100%;
            section{
                .flex-header{
                    flex-direction: column;
                }
            }
        }
    }    
}

.field .formHelp{
    font-size: 17px;
}

.wg-drop.country-selector li:hover {
		background-color: #f1ebc5;
		.wg-li.focus a, 
		a:hover {
			color: black;
	}
}

@media (max-width: @plone-screen-sm-min){
    .blog-entry{
        > div{
            float: none !important;
            width: 100% !important;
        }
    }
    section.calendar-wrapper .calendar-right section > div .event-item-info{
        flex-direction: row !important;
    }

    .folder-summary{
        .folder-summary-item {
            .item-image{            
                img{
                    padding-right: 0; 
                }
            }
        }
    }
}

/*
     CSS for the main interaction
    */
    .tabset > input[type="radio"] {
      position: absolute;
      left: -200vw;
    }

    .tabset .tab-panel {
      display: none;
    }

    .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
    .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
    .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
    .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
    .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
    .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
      display: block;
    }

    /*
     Styling
    */
    .tabset > label {
      position: relative;
      display: inline-block;
      padding: 15px;
      border: 1px solid transparent;
      border-bottom: 0;
      cursor: pointer;
      font-weight: 600;
    }

    .tabset > label:hover,
    .tabset > input:focus + label {
      color: #57bc90;
    }

    .tabset > label:hover::after{
      background: #57bc90;
    }

    .tabset > input:checked + label {
      border-color: #ccc;
      border-bottom: 1px solid #fff;
      margin-bottom: -1px;
      color: #57bc90;
    }

    .tab-panel {
      padding: 30px 0;
      border-top: 1px solid #ccc;
    }


/*    event summary*/
    .event.summary{
        background-image: url(/++theme++my-theme/images/agh-pre-made-thread2.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }


/*    tile event and event card listing style*/
      .tile-eventCardListing,
      .tile-CardListing,
      #eventCardListing{
        .event-card-wrapper{
          position: relative;
          overflow: hidden;
          margin-bottom:20px;
          background-color: #F2ECCB;

          img{
            width: 100%;
          }

          &:after{
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            -webkit-clip-path: polygon(0 calc(0% + 4vw), 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 calc(0% + 4vw), 100% 0, 100% 100%, 0 100%);
          }        

          &:nth-child(8n+1){
            background-color: #aaf76a75;
            &:after{
              background-image:url(++theme++my-theme/images/agh-pre-made-thread3.svg);
            }
          }
          &:nth-child(8n+2){
            background-color: #c9e3d875;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread2.svg);
            }
          }
          &:nth-child(8n+3){
            background-color: #36d2ec75;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread3.svg);
            }
          }
          &:nth-child(8n+4){
            background-color: #eff55b75;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread4.svg);
            }
          }
          &:nth-child(8n+5){
            background-color: #ffcf8c75;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread5.svg);
            }
          }
          &:nth-child(8n+6){
            background-color: #f2f1cc75;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread6.svg);
            }
          }
          &:nth-child(8n+7){
            background-color: #fdb3dc75;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread7.svg);
            }
          }
          &:nth-child(8n+8){
            background-color: #ff175b75;
            &:after{
              background-image: url(/++theme++my-theme/images/agh-pre-made-thread8.svg);
            }
          }

          .event-card-content{
            padding: 30px 15px;

            > div{
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              margin-bottom: 10px;

              > *{
                flex-basis: 32px;

                &:nth-child(2) {
                  margin-top: 0;
                  flex-grow: 1;
                }
              }

              i{
                color: #fff;
                background-color: #051C2C;
                border-radius: 50%;
                text-align: center;
                font-size: inherit;
                width: 32px;
                height: 32px;
                margin-right: 20px;

                &:before{
                  transform: translate(0%, 37%);
                  display: inline-block;
                }
              }
            }

            .btns-wrap{
              flex-basis: 128px;
              margin-bottom: 0;
              a{
                margin-right: 5px;
                flex-grow: 0;
              }
            }

                    
          }

          
        }
      }       

      @media (min-width: 768px){
        .tile-eventCardListing,
        .tile-CardListing,
        #eventCardListing{

            div.event-card-wrapper {
              position: relative;
              overflow: hidden;
              width: 100%;
              border: none;
              margin-top:20px;
              min-height: 280px;

              &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 35%;
                z-index: 0;
                min-width: 67%;
                height: 100%;
                background-color: #F2ECCB;
                transform: skew(-7deg);
                -webkit-clip-path: none;
                clip-path: none;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
              }    

              .event-card-content {
                position: relative;
                z-index: 12;
                margin-left: 40%;
                padding: 20px 20px 20px 0;
              } 

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
                  position: absolute;
                }
            }
        }
    }
    /* end tile event and event card listing style*/

/*collection filter tile*/
.pat-collectionfilter{
    margin: 0.5em 0;
    padding: 0.5em;
    border: 1px solid #051C2C;
    position: relative;
    overflow: hidden;
    .filterContent{
        ul{
            margin-bottom: 0;
        }
    }
}
.collectionSearch .searchContent, 
.portlet.collectionSearch .searchContent, 
.collectionFilter .filterContent, 
.portlet.collectionFilter .filterContent, 
.collectionSortOn .filterContent, 
.portlet.collectionSortOn .filterContent {
    padding: 1em 0;
}
.collectionSearch .searchContent select, 
.portlet.collectionSearch .searchContent select, 
.collectionFilter .filterContent select, 
.portlet.collectionFilter .filterContent select, 
.collectionSortOn .filterContent select, 
.portlet.collectionSortOn .filterContent select {
    width: 100%;

    display: block;
    padding: 10px 12px;
    font-size: 20px;
    line-height: 1.42857143;
    color: #767676;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/*profile new view*/
#profile-view-new{
    .circle-img{
        position: relative;
        overflow: hidden;
        text-align: center;
        border-radius: 50%;
        width: 270px;
        height: 270px;
        position: relative;
        overflow: hidden;

        img{
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            object-position: center;
        }
    }
    .plain-angle{
        content: " ";
        clear: both;
        display: block;
        background: #ccddd3;
        background-image: url(/++theme++my-theme/images/agh-pre-made-thread2.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 180px;
        padding: 15px;
    }
   
}


#circle-btn{
    display: flex;
    align-items: center;

    a{ 
        display: flex;
        width: 32px;
        height: 32px;
        color: #fff;
        background-color: #051C2C;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
}

.bordered-listing{
    > div{
        border: 1px solid #051C2C;
        margin-bottom: 20px;
        min-height: 100px;
        overflow: hidden;
        padding: 15px 0;
    }
    .img-wrapper{
        img{
            width: 100%;
            min-height: 200px;
            object-fit:cover;
            object-position:center;
            display:block;
        }
    }
}

ul.member-listing{
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    > li {
        flex: 1 0 25%; 
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        border-bottom: 1px solid #00000015;
        padding-bottom: 40px;

        .member-image{
            &.circle-img{
                position: relative;
                overflow: hidden;
                border-radius: 50%;
                width: 220px;
                height: 220px;
                margin-bottom: 15px;
                box-shadow: 0 0 5px #00000025;

                img{
                    width: 100%;
                    height: 100% !important;
                    object-fit:cover;
                    object-position:center;
                    display:block;
                }
            }
        }
        .member-text{
                padding: 0 25px;
                width: 100%;
            > ul{
                padding-left: 0;
                list-style: none;
                li{
                    margin-bottom: 0;
                }
            }
        }

        
    }
}
    
  

/*member row listing*/
.member-row{
    .member-row-item{
        position: relative;
        overflow: hidden;
        width: 100%;
        background-color: #fff;
        border: none;
        margin-bottom: 20px;        
        border: 1px solid #051C2C;

        .item-image{
            position: unset;

            .img-wrapper{
                width: 180px;
                height: 180px;
                margin: 30px auto;
                position: relative;
                overflow: hidden;

                &.rounded{
                    border-radius: 50%;
                    background-color: #f2f1cc75;
                }

                > img{
                    height: 100% !important;
                    width: 100% !important;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .item-info{
            padding: 70px 15px 15px 15px;
            position: relative;
            overflow: hidden;
            min-height: 240px;
            -webkit-clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);

            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: auto;
                min-height: 240px;
                width: 100%;
            }

            > *{
                position: relative;
                z-index: 13;
                top: 0;
                left: 0;
            }
            > h4{
                margin-bottom: 0;
            }

            > ul{
                padding-left: 0;
                list-style: none;

                li{
                    padding: 5px 0;

                    &:first-child{
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .plone-navbar-nav > li.has_subtree a {
        display: block;
    }
    #portal-footer-wrapper {
        padding-top: 300px;
    }
    .member-row{
        .member-row-item{
            max-height: 240px;
            .item-image{
                position: absolute;
                top: 0;
                left: 0;
                width: 30%;

                .img-wrapper{
                    width: 180px;
                    height: 180px;
                    margin: 30px auto;
                    position: relative;
                    overflow: hidden;
                }

                &.o-fit{
                    width: 33% !important;

                    img {
                        width: 100%;
                        min-height: 320px;
                        object-fit: cover;
                        object-position: center;
                        display: block;
                    }
                }
            }

            .item-info{
                z-index: 12;
                margin-left: 35%;
                padding: 20px 20px 20px 0;
                top: 0;
                position: unset;
                -webkit-clip-path: none;
                clip-path: none;

                p{
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 30%;
                    z-index: 0;
                    width: 72%;
                    height: 240px;
                    transform: skew(-7deg);
                    -webkit-clip-path: none;
                    clip-path: none;
                    background-color: #F2ECCB;  
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;                
                }
                

                > *{
                    position: relative;
                    z-index: 13;
                }
            }

            &:nth-child(8n+1){
                .item-info{
                    background-color: #aaf76a75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread1.svg);
                    }
                }
            }
            &:nth-child(8n+2){
                .item-info{
                    background-color: #c9e3d875;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread2.svg);
                    }
                }
            }
            &:nth-child(8n+3){
                .item-info{
                    background-color: #36d2ec75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread3.svg);
                    }
                }
            }
            &:nth-child(8n+4){
                .item-info{
                    background-color: #eff55b75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread4.svg);
                    }
                }
            }
            &:nth-child(8n+5){
                .item-info{
                    background-color: #ffcf8c75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread5.svg);
                    }
                }
            }
            &:nth-child(8n+6){
                .item-info{
                    background-color: #f2f1cc75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread6.svg);
                    }
                }
            }
            &:nth-child(8n+7){
                .item-info{
                    background-color: #fdb3dc75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread7.svg);
                    }
                }
            }
            &:nth-child(8n+8){
                .item-info{
                    background-color: #ff175b75;
                    &:after{
                      background-image:url(++theme++my-theme/images/agh-pre-made-thread8.svg);
                    }
                }
            }
        }
    }

}

/*related item with icon*/
.new-related.relatedItems{
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin-top: 15px;
}
.related-w-icon{
    list-style: none;
    padding-left: 0;

    li{
        border: 2px solid #051C2C;
        margin-bottom: 15px;

        .icon-wrapper {
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            min-height: 150px;
            justify-content: center;

            > span{
                font-size: 125px;

                &:before{
                    width: auto !important;
                }
            }
        }

        .info-wrapper{
            min-height: 150px;
        }

        &:nth-child(even) > .info-wrapper{
            background-image: url(/++theme++my-theme/images/agh-pre-made-thread2.svg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:nth-child(odd) > .info-wrapper{
            background-image: url(/++theme++my-theme/images/agh-pre-made-thread6.svg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;    
        }
    }
}
.cardicon-listing{
    border: 1px solid #051C2C;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    .cardicon-icon{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        min-height: 256px;
        justify-content: center;

        > span{
            font-size: 140px;

            &:before{
                width: auto !important;
            }
        }
    }

    .cardicon-text{
        min-height: 256px;
    }

    &:nth-child(even) > .cardicon-text{
        background-image: url(/++theme++my-theme/images/agh-pre-made-thread2.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &:nth-child(odd) > .cardicon-text{
        background-image: url(/++theme++my-theme/images/agh-pre-made-thread6.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;    
    }
}

nav.pagination{
    ul{
        padding-left: 0;
    }
}
