#form-widgets-title {
    padding-bottom: 13px;
    margin: 0 0 14px;
    border-bottom: 1px solid #cccccc;
    font-size: 60px;
    font-family: @plone-font-family-heading; 
    font-weight: 700;
    line-height: 1.1;
    display: inline-block;
}
#form-widgets-description {
    font-size: 25px;
    font-weight: 700;
    color: #767676;
    margin: 0 0 42px;
    display: inline-block;
}

.text-left{text-align: left}
.text-center{text-align: center}
.text-rught{text-align: right}
.text-justify{text-align: justify;}

.link-category.font-20{
    font-size: 20px !important;
}

.half-width{
    width: 50% !important;
}

.black { color: #000  !important}
.white { color: #FFF  !important}
.grey { color: #DDD  !important}
.black-background { background-color: #000  !important}
.white-background { background-color: #FFF  !important}
.grey-background { background-color: #DDD  !important}
.primary-background{ background-color: @plone-link-color }
.primary-color{ color: @plone-link-color }

.agh-bg-1{background-color: #75cbeb !important}
.agh-bg-2{background-color: #ccddd3 !important}
.agh-bg-3{background-color: #e7b7d8 !important}
.agh-bg-4{background-color: #f1ebc5 !important}
.agh-bg-5{background-color: #F3E85C !important}
.agh-bg-6{background-color: #ffc77e !important}
.agh-bg-7{background-color: #c0e55b !important}

.agh-bg-1-75{background-color: #75cbeb75 !important}
.agh-bg-2-75{background-color: #ccddd375 !important}
.agh-bg-3-75{background-color: #e7b7d875 !important}
.agh-bg-4-75{background-color: #f1ebc575 !important}
.agh-bg-5-75{background-color: #F3E85C75 !important}
.agh-bg-6-75{background-color: #ffc77e75 !important}
.agh-bg-7-75{background-color: #c0e55b75 !important}

.hover-bg-disabled:hover,
.hover-bg-private:hover{
    background-color: #FF595A !important;
    color: #051C2C  !important;
}
.hover-bg-public:hover{
    background-color: #6FCFEB  !important;
    color: #051C2C  !important;
}
.hover-bg-internal:hover{
    background-color: #FFCC8B !important;
    color: #051C2C  !important;
}

.bg-th{
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread6.svg);
    background-color: #F2ECCB;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    &:after{
        content: " ";
        display: block;
        height: 62px;
        background-color: #ffc77e;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
    }

    a{
        img{
            position: relative;
            z-index: 2;
        }
    }
}
.bg-th + a{
    text-transform: uppercase;
    font-weight: bold;
}

.aghrow-bg-1:after{
    background-color: #75cbeb !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread1.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}
.aghrow-bg-2:after{background-color: #ccddd3 !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread2.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}
.aghrow-bg-3:after{background-color: #e7b7d8 !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread3.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}
.aghrow-bg-4:after{background-color: #f1ebc5 !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread4.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}
.aghrow-bg-5:after{background-color: #F3E85C !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread5.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}
.aghrow-bg-6:after{background-color: #ffc77e !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread6.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}
.aghrow-bg-7:after{background-color: #c0e55b !important;
    background-image: url(/++theme++my-theme/images/agh-pre-made-thread7.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;}

// display flex
.flex {display: flex;}
.flex-wrap {flex-wrap: wrap;}
.flex-between {justify-content: space-between;}
.flex-stretch {align-items: stretch;}
.flex-flow-reverse{flex-flow: row-reverse}
.flex-flow-reverse-wrap{flex-flow: row-reverse wrap;}
.flex-header-center{
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-pack: justify; 
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;    
}
.flex-header{
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-pack: justify; 
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.position-relative{position: relative;}

.padding-0{padding:0!important}
.padding-0-10{padding:0 10px!important}
.padding-0-20{padding:0 20px!important}
.padding-10{padding:10px!important}
.padding-15{padding:15px!important}
.padding-10-0{padding:10px 0!important}
.padding-20{padding:20px!important}
.padding-20-0{padding:20px 0!important}
.padding-30{padding:30px!important}
.padding-40{padding:40px!important}
.padding-0-40{padding-left: 40px !important}
.top-angle-padding{padding-top: 150px}
.bottom-angle-padding{padding-bottom: 150px}
.top-bottom-angle-padding{padding-top: 150px;padding-bottom: 150px}

.margin-0{margin:0!important}
.margin-20-0{margin:20px 0!important}
.margin-0-15{margin:0 -15px!important}
.margin-10{margin:10px!important}
.margin-10-0{margin:10px 0!important}
.margin-120-0{margin:-120px 0 -120px 0}
.margin-bottom-40{
    margin: 0 0 40px 0;
}

// fontsize
.font8{font-size: 8pt}
.font10{font-size: 10pt}
.font12{font-size: 12pt}
.font14{font-size: 14pt}
.font18{font-size: 18pt}
.font24{font-size: 24pt}
.font36{font-size: 36pt}

.btn-circle {
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 50%;
  background: #75cbeb;
  display: inline-block;
  color: #fff;
  i:before {
    transform: translate(0%, 75%);
    display: inline-block;
  }
}

ul.transition-private-btn{
    li{
        margin-bottom: 10px;
    }
}

.agh-dropdown-button{
  display: inline;
  vertical-align: inherit;
  button.btn-circle{
    font-size: 20px;
    border-color: transparent;
    background-color: #051C2C;
    i:before {
      @media (min-width: 768px){
        transform: translate(-50%, -10%) !important;
      }      
      display: inline-block;
    }
  }
  button.btn-circle:hover{
    background-color: #75cbeb;
  }
  button.btn-circle.focus:focus {
    outline: none;
  }
  .dropdown-menu {
    margin: 7px 0 0;
    right: 0;
    left: initial;
  }
}

// custom toolbar
#custom-toolbar{
    height: 50px;
    margin-bottom: 20px;
    padding: 5px 15px;
    > * {
        vertical-align: -webkit-baseline-middle;
        vertical-align: -moz-middle-with-baseline;
    }
    .agh-dropdown-button{
        #actionsMenuButton:focus{
            outline: none;
        }
    }
    .agh-dropdown-button.dropdown.pull-right{
        > * {
            vertical-align: -webkit-baseline-middle;
        }
    }
    .btn-circle {
        width: 32px;
        height: 32px;
        font-size: 20px;
        line-height: 1.42857143;
        background: @plone-link-color;
        
        i:before {
            transform: translate(0%,20%);
            display: inline-block;
        }
    }
    .btn-circle:focus,
    .btn-circle:hover{
        color: #f5f5f5;
    }
}


// images
img{height:auto}
img.img-responsive{margin-left:0;margin-right:0;max-width:100%;height:auto}
.fullwidth{width: 100% !important}

// angle padding
.angle-padding{padding-top:300px!important;padding-bottom:300px!important}

.angle-linear{
    background-blend-mode: overlay;
    background-position: 50% 0;
    background-repeat:no-repeat;
    background-size:cover;
}
.angle-linear:before{
    content: "";
    position: absolute;
    top: initial;
    left: 0;
    right: 0;
    height: 7vw;
    background: linear-gradient(to top left, #282f49 49.5%, white 50%);
}
.angle-linear-bottom:after,
.angle-linear:after{
    content: "";
    position: absolute;
    bottom: 0em;
    left: 0;
    right: 0;
    height: 7vw;
    background: linear-gradient(to bottom right, #282f49 49.5%, white 50%);
}

.angle-linear-top:before{
    content: "";
    position: absolute;
    top: 0em;
    left: 0;
    right: 0;
    height: 7vw;
    background: linear-gradient(to top left, #EDBDDB 49.5%, white 50%);
}

// round icon 
.round-icn {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
    padding: 0;
    font-size: 24px;
    color: #fff;
    border: none;
    border-radius: 50%;
    background: @plone-link-color;
    outline: 0;
    cursor: pointer;
    margin-right: 5px;
    i {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 1em;
        top: 1em;
        color: #fff;
        font-size: 32px;
    }
  }

.round-bordered-icon{
    border: 2px solid @agh-green;
    border-radius: 50%;
    a,span{
        color: @agh-green;
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        height: 48px;
        width: 48px;   
        i{
            font-size: 24px;
        }   
    }
}

// expander
.expander{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70vw; // width or max-width must be specified   
    
    > ul{
        height: 0;
        overflow: hidden;
        -moz-transition: height 1s ease;
        -webkit-transition: height 1s ease;
        -o-transition: height 1s ease;
        transition: height 1s ease;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }
    &:hover,
    &:hover > ul {
        white-space: normal;
        height: auto;
        li:first-child{
            margin-top: 10px;
        }
    }

}

/* calendar */
#calendar.fc{
	table.fc-header{
        border: none;
        td.fc-header-left,
        td.fc-header-center,
        td.fc-header-right{
            background-color: rgb(192, 229, 91);
        }
        td.fc-header-center{
            h2.fc-header-title{
                margin-top: 11px;
            }
        }
        .fc-state-active{
            span{
                font-weight: bold;
            }
        }
        table{          
            background-color: rgb(192, 229, 91);  
            border: none;
            border-radius: 0;
        }
        tr,td{
            border: none;
        }
        .fc-state-default,a{
            border-width: 0;
            border: none ;
            color: black;
            margin-top: 20px;
            span{
                background: none transparent;
                border: none;
                padding: 0;
                color: black;
            }
        }
    }  
    .fc-content{
        .fc-event, 
        .fc-agenda 
        .fc-event-time{
            border-style: solid;
            border-color: rgb(117, 203, 235);
            background-color: rgb(117, 203, 235);
            color: #fff;
            a{
                border-style: solid;
                border-color: rgb(117, 203, 235);
                background-color: rgb(117, 203, 235);
                color: black;

            }
        }
        .fc-state-highlight{
            background-color: rgb(241, 230, 72);
        }
    }
}

/*button 100*/
.btn-100 {
    width: 100%;
    padding: 10px;
    display: block;
    text-align: center;
}

/*100% width*/
.w-100{
    width: 100% !important;
    max-width: 100% !important;
}

/*remove float*/
.float-none{
    float: none !important;
}

/*dispaly inline list*/
.d-inline{
    display: inline !important;
    > a{
        display: inline !important;

        span{
            padding-left: 0 !important;
        }

        &:before{
            display: none !important;
        }
    }

    label{
        .filterLabel{
            padding-left: 1em !important;
        }
    }
}

/*liked items*/
ul.liked-items{
    padding-left: 20px;
    list-style: none;
    margin-bottom: 40px;
    li{
        border-bottom: 1px solid #ddd;
        padding: 10px 0;   

        &.w-icon{
            a{
                display: flex;
                gap: 10px;
                span[class^="ag_icon-"]{
                    font-size: 32px;
                }
            }
        }     
    }
}
div.liked-profiles{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 10px;
    a{
        height: 48px;
        width: 48px;
        border-radius: 50%;
        display: block;
        position: relative;
        overflow: hidden;

        img{
            height: 100% !important;
            width: 100%;
            object-fit: conver;
        }
    }
}

@media (min-width: @plone-screen-md-min) {
    .flex-header-center{
        flex-wrap: initial;
        flex-direction: row;
        > p{
            margin-right: 20px;
        }
    }
    .flex-header{
        flex-direction: row;
    }
    // header
    #content-header{
        #portal-top{
            flex-direction: row;
        }
    } 
    // leadimage  
    .leadimg-left-wrapper img{
        width: 100%;
    }
}

@media (max-width: @plone-screen-md-min){
    .half-width{
        width: 100% !important;
    }
}

/*disabled link*/
.hover-bg-disabled{
    pointer-events: none;
    opacity: 0.5;
}

/*added icon*/
.ag_icon-web-page:before {
  content: "\e921";
}
.ag_icon-database:before {
  content: "\e911";
}
.ag_icon-software:before {
  content: "\e91a";
}