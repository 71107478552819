//*// MIXIN FONT FACE //*//
@theme-roboto-path: '/++theme++my-theme/barceloneta/less/';

.font-face(@font-path; @file-name; @font-family; @font-weight; @font-style; @svg-id; @local, ~''; ) {
  @font-face {
    font-family: @font-family;
    src: url('@{theme-roboto-path}@{font-path}@{file-name}.eot');
    src: @local url('@{theme-roboto-path}@{font-path}@{file-name}.eot?#iefix') format('embedded-opentype'),
    url('@{theme-roboto-path}@{font-path}@{file-name}.woff') format('woff'),
    url('@{theme-roboto-path}@{font-path}@{file-name}.ttf') format('truetype'),
    url('@{theme-roboto-path}@{font-path}@{file-name}.svg#@{svg-id}') format('svg');
    font-weight: @font-weight;
    font-style: @font-style;
  }
}

@font-face {
  font-family:'Work Sans';
  src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold.eot');
src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold.eot?#iefix') format('embedded-opentype'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold.woff2') format('woff2'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold.woff') format('woff'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold.svg#Work Sans Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Work Sans';
  src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold Italic.eot');
src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold Italic.eot?#iefix') format('embedded-opentype'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold Italic.woff2') format('woff2'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold Italic.woff') format('woff'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Bold Italic.svg#Work Sans Bold Italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Work Sans';
  src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Italic.eot');
src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Italic.eot?#iefix') format('embedded-opentype'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Italic.woff2') format('woff2'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Italic.woff') format('woff'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Italic.svg#Work Sans Italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Work Sans';
  src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Regular.eot');
src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Regular.eot?#iefix') format('embedded-opentype'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Regular.woff2') format('woff2'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Regular.woff') format('woff'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans Regular.svg#Work Sans Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Work Sans SemiBold';
  src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold.eot');
src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold.woff2') format('woff2'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold.woff') format('woff'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold.svg#Work Sans SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}

@font-face {
  font-family:'Work Sans SemiBold';
  src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold Italic.eot');
src: url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold Italic.eot?#iefix') format('embedded-opentype'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold Italic.woff2') format('woff2'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold Italic.woff') format('woff'),
  url('/++theme++my-theme/barceloneta/less/work-sans/Work Sans SemiBold Italic.svg#Work Sans SemiBold Italic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-2044;
}