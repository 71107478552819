//*// SITE NAV //*//

.plone-nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);
  li {
    position: relative;
    display: block;
    &.has_subtree a {
      padding-right: 2.5em;
    }
    label {
      margin: 0;
    }
    a {
      position: relative;
      display: block;
      padding: @plone-sitenav-link-padding;
      color: black;
      // font-size: ceil((@plone-font-size-base * 1.15));;
      font-size: ceil((@plone-font-size-base * .9));;
      hyphens: auto;
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: @plone-sitenav-link-hover-bg;
        color: black !important;
      }
    }
    // Navigation sublevels
    ul {
      padding-left: 0.5em;
      visibility: hidden;
      opacity: 0;
      height: 0;
      li {
        a {
          // font-size: ceil((@plone-font-size-base * 1.1));;
          font-size: ceil((@plone-font-size-base * .9));;
          background-color: @plone-sitenav-bg;
        }
      }
    }
    .opener {
      display: none;
      & + label:after {
        transform: rotate(0deg);
        transition: ease-in 0.35s;
      }
      @media (max-width: @plone-grid-float-breakpoint) {
        &:checked + label:after {
          transform: rotate(-180deg);
          transition: ease-in 0.35s;
        }
      }
      & ~ ul {
        transition: ease-out 0.35s;
      }
      &:checked ~ ul {
        height: auto;
        transition: ease-in 0.35s;
      }
      @media (max-width: @plone-grid-float-breakpoint) {
        &:checked ~ ul {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    label {
      position: absolute;
      right: 0em;
      top: 0em;
      height: 100%;
      width: 4em;
      text-align: center;
      &:after {
        background: darken(@plone-link-color, 7%);
        border-radius: @plone-input-border-radius;
        content: "▼";
        cursor: pointer;
        display: inline-block;
        line-height: 1;
        width: 1.5em;
        height: 1.5em;
        font-size: 1.25em;
        padding: 0.25em;
        right: 0.5em;
        top: 0.45em;
        position: absolute;
      }
    }
  }

  @media (min-width: @plone-grid-float-breakpoint) {
    li {
      label {
        top: -.3em;
        width: 2em;
        &:after {
          top: 1.25em;
        }
      }
      &.has_subtree {
        .has_subtree li {
          label {
            right: 0.25em;
            &:after {
              top: 0.5em;
            }
          }

        }
      }
      label {
        &:after {
            width: 2.0em;
            height: 2.0em;
            font-size: 0.75em;
            padding: 0.5em 1em 0.5em 0;
            background: transparent;
        }
      }
      ul {
        position: absolute;
        z-index: 100;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: ease-out 0.75s;
        li {
          a {
            min-width: 14em;
            display: inline-block;
          }
        }
      }
    }
    .has_subtree:hover > ul,
    .has_subtree .has_subtree:hover > ul {
      opacity: 1;
      visibility: visible;
      transition: ease-in .35s;
    }
    .has_subtree .has_subtree ul {
      position: absolute;
      z-index: -1;
      left: 0em;
      top: 0px;
    }
    .has_subtree .has_subtree:hover > ul {
      // we only shift the submenus to the right if visible
      // to prevent horizontal scrollers if not needed
      // left: 16em;
      left: 12.7em;
    }
  }
}

.plone-navbar {
  .clearfix();
  background-color: @plone-sitenav-bg;
  color: white;
  position: relative;
  min-height: @plone-sitenav-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
  margin-bottom: @plone-sitenav-margin-bottom;
  box-shadow: 0 1px 3px rgba(0,0,0,.17);

  // Prevent floats from breaking the navbar
  &:extend(.clearfix all);
}

.plone-navbar-header {
  .clearfix(); //without extend
  @media (min-width: @plone-grid-float-breakpoint) {
    float: left;
  }
}

.plone-navbar-collapse {
  overflow-x: visible;
  padding-right: @plone-sitenav-padding-horizontal;
  padding-left:  @plone-sitenav-padding-horizontal;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
  &:extend(.clearfix all);
  -webkit-overflow-scrolling: touch;

  display: none;
  &.in {
    display: block;
  }

  @media (min-width: @plone-grid-float-breakpoint) {
    width: auto;
    border-top: 0;
    box-shadow: none;
    &.plone-collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }
    &.in {
      overflow-y: visible;
    }
    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.plone-navbar-toggle {
  z-index: 1; //just in case
  position: relative;
  float: right;
  margin-top:  8px;
  margin-bottom: 6px;
  padding: 6px 10px;

  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid white;
  border-radius: @plone-border-radius-base;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }
  &:hover {
  	background-color: darken(@plone-sitenav-link-hover-bg, 15%);
  	border-color: darken(@plone-sitenav-link-hover-bg, 25%);
  }

  // Burgerbar (uncomment for a ≡ menu and comment menu just below)
  /*
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: white;
    margin-top: 2px;
  }
  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
  .icon-bar + .icon-bar + .icon-bar {
    margin-bottom: 3px;
  }
  //end burgerbar icon-bar
  */

  // Menu (That option with a word, witch is language-dependant, is to ensure a higher tap ratio on the menu)
  .icon-bar::after {
  	content:"Menu";
  	position: relative;
  	color: white;
  }
  .icon-bar + .icon-bar::after {content: "";}
  //end menu icon-bar

  @media (min-width: @plone-grid-float-breakpoint) {
    display: none;
  }
}

.plone-navbar-nav {
  margin: 0 -@plone-sitenav-padding-horizontal 0;

  > li > a {
    padding-top:    10px;
    padding-bottom: 10px;
    // line-height: @plone-line-height-computed;
    line-height: 18px;
    
  }
  // use selected for the last item in the structure

  .current > a,
  .selected > a {
    &,
    &:hover,
    &:focus {
      color: white;
      background-color: @plone-sitenav-link-hover-bg;
    }
  }

  // Uncollapse the nav
  @media (min-width: @plone-grid-float-breakpoint) {
    float: left;
    > li {
      float: left;
      &.has_subtree a {
        padding-right: 1.7em;
      }
      > a {
        padding-top:    @plone-sitenav-padding-vertical;
        padding-bottom: @plone-sitenav-padding-vertical;
      }
      label {
        // font-size: 1em;
        font-size: .8em;
        background: transparent;
        &:after {
        }
      }
      li.has_subtree label:after {
        transform: rotate(-90deg);

      }
    }

    &.navbar-right:last-child {
      margin-right: -@plone-sitenav-padding-horizontal;
    }

    .has_subtree.inPath > a {
      &,
      &:hover,
      &:focus {
        color: white;
        background-color: @plone-sitenav-link-hover-bg;
      }
    }

  }
}
